import { Button, Card, Empty, Space, Table, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  usePartnerTariffGroupQuery,
  usePartnerTariffGroupTariffActivateMutation,
  usePartnerTariffGroupTariffArchiveMutation,
  usePartnerTariffGroupTariffsQuery,
} from 'features/partners-tariffs-groups/queries';
import { useIsSubpartner } from 'core/providers/PartnerProvider';
import dayjs from 'dayjs';
import RestrictionView from 'views/PartnerTariffGroup/RestrictionView';
import { Padding } from 'components/Padding';

import styles from './PartnerTariffGroupDetails.module.css';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Vat percent',
    dataIndex: 'vatPercent',
    key: 'vatPercent',
  },
  {
    title: 'Step size',
    dataIndex: 'stepSize',
    key: 'stepSize',
  },
];

export interface PartnerTariffsDetailsProps {
  partnerId?: string;
  tariffGroupId?: string;
  rightAction?: ReactNode;
  showDetails?: boolean;
}

export function PartnerTariffGroupDetails({
  partnerId,
  rightAction,
  tariffGroupId,
  showDetails = true,
}: PartnerTariffsDetailsProps) {
  const isSubpartner = useIsSubpartner();

  const { data: tariffGroup, isInitialLoading } = usePartnerTariffGroupQuery(
    {
      partnerId: partnerId!,
      groupId: tariffGroupId!,
    },
    {
      enabled: !!partnerId && !!tariffGroupId && !isSubpartner,
    },
  );

  const { data: tariffGroupTariffs, refetch } =
    usePartnerTariffGroupTariffsQuery(
      {
        partnerId: partnerId!,
        groupId: tariffGroupId!,
      },
      {
        enabled: !!partnerId && !!tariffGroupId && showDetails && !isSubpartner,
      },
    );

  const activateTariffMutation = usePartnerTariffGroupTariffActivateMutation();
  const archiveTariffMutation = usePartnerTariffGroupTariffArchiveMutation();

  if (isInitialLoading) {
    return <div>Loading...</div>;
  }

  if (!partnerId || !tariffGroupId || isSubpartner) {
    return <Empty />;
  }

  return (
    <div className={styles.tariffs_content}>
      <Padding isHorizontal>
        <Typography.Title level={4} style={{ margin: 0 }}>
          <Link
            style={{ color: 'rgba(0, 0, 0, 0.88)' }}
            to={`/pricing/group/${tariffGroup?.id}`}
          >
            {tariffGroup?.name ?? '...'}
          </Link>
        </Typography.Title>
        <div>{rightAction}</div>
      </Padding>
      {tariffGroupTariffs &&
        showDetails &&
        tariffGroupTariffs.map((tariff) => (
          <Card key={tariff?.id}>
            <div
              style={{
                position: 'absolute',
                right: 16,
                top: 16,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {!tariff.activatedAt ? (
                <Button
                  type={'primary'}
                  onClick={() => {
                    activateTariffMutation.mutate(
                      [
                        {
                          partnerId,
                          groupId: tariffGroupId,
                          tariffId: tariff.id,
                        },
                      ],
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  }}
                >
                  Activate
                </Button>
              ) : null}
              {!tariff.archivedAt && tariff.activatedAt ? (
                <Button
                  type={'primary'}
                  onClick={() => {
                    archiveTariffMutation.mutate(
                      [
                        {
                          partnerId,
                          groupId: tariffGroupId,
                          tariffId: tariff.id,
                        },
                      ],
                      {
                        onSuccess: () => refetch(),
                      },
                    );
                  }}
                >
                  Archive
                </Button>
              ) : null}
            </div>
            <div>
              <Typography.Text type="secondary">Activated at:</Typography.Text>{' '}
              {tariff?.activatedAt
                ? dayjs(tariff?.activatedAt).format('DD/MM/YYYY HH:mm')
                : '-'}
            </div>
            <div>
              <Typography.Text type="secondary">Archived at:</Typography.Text>{' '}
              {tariff?.archivedAt
                ? dayjs(tariff?.archivedAt).format('DD/MM/YYYY HH:mm')
                : '-'}
            </div>
            <div>
              <Typography.Text type="secondary">Currency:</Typography.Text>{' '}
              {tariff?.currency}
            </div>
            <div>
              <Typography.Text type="secondary">Type:</Typography.Text>{' '}
              {tariff?.type}
            </div>
            <div>
              <Typography.Text type="secondary">Min price:</Typography.Text>{' '}
              {tariff?.minPrice?.amount}
            </div>
            <div>
              <Typography.Text type="secondary">Max price:</Typography.Text>{' '}
              {tariff?.maxPrice?.amount}
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              {(tariff?.elements ?? []).map(
                ({ id, restrictions, tariffPrices }) => (
                  <React.Fragment key={id}>
                    <Card>
                      <Table
                        size={'small'}
                        pagination={false}
                        loading={isInitialLoading}
                        className={styles.tariff_table}
                        dataSource={Object.entries(tariffPrices)?.map(
                          ([k, v]) => ({
                            key: k,
                            name: k,
                            price: v.price,
                            vatPercent: v.vatPercent,
                            stepSize: v.stepSize,
                          }),
                        )}
                        columns={columns ?? []}
                      />
                      <Space direction="vertical" style={{ width: '100%' }}>
                        {restrictions &&
                          restrictions.map((restriction, index) => (
                            <RestrictionView
                              key={index}
                              restriction={restriction}
                            />
                          ))}
                      </Space>
                    </Card>
                  </React.Fragment>
                ),
              )}
            </div>
          </Card>
        ))}
    </div>
  );
}
