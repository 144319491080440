import { memo, useState, useEffect } from 'react';
import cn from 'classnames';
import {
  Spin,
  Typography,
  Modal,
  Button,
  Form,
  Space,
  Alert,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Padding } from 'components/Padding';
import { useParams } from 'react-router-dom';
import { usePartnerId } from 'core/providers/PartnerProvider';
import { usePartnerTariffGroupTariffQuery } from 'features/partners-tariffs-groups/queries/usePartnerTariffGroupTariffQuery';
import { TariffForm } from 'pages/Pricing/TariffDetails/TariffForm';
import { ElementsList } from 'pages/Pricing/TariffDetails/ElementsList';
import {
  Tariff,
  TariffElement,
  TariffFormUpdateData,
  TariffUpdateDataWithElements,
} from 'features/partners-tariffs-groups/types';
import { ElementForm } from 'pages/Pricing/TariffDetails/ElementForm';
import { isNotNull, isDefined, hasValue } from 'core/utils/typeguards';
import {
  createTariffElement,
  updateElementsList,
  temporaryElementPrefix,
} from 'pages/Pricing/TariffDetails/TariffDetails.utils';
import { PricesView } from 'pages/Pricing/TariffDetails/ElementsList/PricesView';
import { RestrictionView } from 'pages/Pricing/TariffDetails/ElementsList/RestrictionView';
import { getTariffStatus, TariffStatus } from 'core/utils/tariffUtils';
import { useCountries } from 'features/catalog';
import { getCurrenciesFromCountries, defaultCurrency } from 'core/utils';
import { PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';

import s from './TariffDetails.module.css';
import { useTariffMutations } from './useTariffMutations';

const { Title } = Typography;

const TariffDetails = () => {
  const partnerId = usePartnerId();
  const { groupId, tariffId } = useParams();

  const { t } = useTranslation();

  const {
    createNewTariff,
    updateDraftTariff,
    updateActiveTariff,
    isLoading: isTariffLoading,
  } = useTariffMutations();

  const [currency, setCurrency] = useState<string>(defaultCurrency);
  const [elements, setElements] = useState<TariffElement[]>([]);
  const [editedElement, setEditedElement] = useState<TariffElement | null>(
    null,
  );

  const { data: countries } = useCountries();
  const currencies = countries ? getCurrenciesFromCountries(countries) : [];

  const isLoadData = !!tariffId && tariffId !== 'new';

  const { data: tariff, isLoading: isFormLoading } =
    usePartnerTariffGroupTariffQuery(
      { partnerId, tariffId: tariffId || '', groupId: groupId || '' },
      { enabled: isLoadData },
    );

  const isActive =
    hasValue(tariff) && getTariffStatus(tariff) === TariffStatus.active;
  const isArchived =
    hasValue(tariff) && getTariffStatus(tariff) === TariffStatus.archived;
  const isReadOnly = isActive || isArchived;
  const status = hasValue(tariff) ? getTariffStatus(tariff) : '';

  useEffect(() => {
    if (tariff) {
      setElements(tariff.elements || []);
      setCurrency(tariff.currency || defaultCurrency);
    }
  }, [tariff]);

  const [form] = Form.useForm();

  const handleElementSubmit = (updated: TariffElement) => {
    const nextElements = updateElementsList(elements, updated);
    setElements(nextElements);
    setEditedElement(null);
  };

  const handleSubmit = (tariffData: TariffFormUpdateData) => {
    const elementsNoId = elements.map((el) => {
      if (el.id.startsWith(temporaryElementPrefix)) {
        return { ...el, id: '' };
      }
      return el;
    });
    const tariffWithElements = {
      ...tariffData,
      elements: elementsNoId,
    } as TariffUpdateDataWithElements;

    if (isActive) {
      updateActiveTariff(tariffWithElements);
      return;
    }

    if (isLoadData) {
      updateDraftTariff(tariffWithElements);
      return;
    }

    createNewTariff(tariffWithElements);
  };

  const handleElementDelete = (element: TariffElement) =>
    Modal.confirm({
      title: t('pricing.delete_element_modal.title'),
      content: (
        <Space direction={'vertical'} size={'small'}>
          <PricesView prices={element.tariffPrices} currencySymbol={currency} />
          {element?.restrictions?.map((r, idx) => (
            <RestrictionView key={idx} restriction={r} />
          ))}
        </Space>
      ),
      width: '600px',
      okText: t('pricing.delete_element_modal.submit'),
      cancelText: t('common.actions.cancel'),
      type: 'confirm',
      onOk: () => setElements([...elements.filter((e) => e.id !== element.id)]),
      closable: true,
      autoFocusButton: 'cancel',
    });

  if (!isDefined(groupId)) {
    return null;
  }

  if (isLoadData && isFormLoading) {
    return <Spin />;
  }

  return (
    <div className={cn(s.wrapper)}>
      {isReadOnly && (
        <Padding>
          <Alert
            message={
              isActive
                ? t('pricing.text.tariff-active')
                : t('pricing.text.tariff-archived')
            }
            type="info"
            showIcon
          />
        </Padding>
      )}
      <Padding>
        <TariffForm
          tariff={tariff}
          onSubmit={handleSubmit}
          form={form}
          isActiveTariff={isActive}
          isArchivedTariff={isArchived}
          currencies={currencies}
          onCurrencyChange={setCurrency}
          status={status}
        />

        <div className={cn(s.box, s.spaceBetween)}>
          <Title level={5}>
            {t('pricing.titles.elements')}
            <Tooltip title={t('pricing.text.sort-elements')}>
              <InfoCircleOutlined
                style={{ marginLeft: 8, cursor: 'pointer' }}
              />
            </Tooltip>
          </Title>
          <Button
            disabled={isReadOnly}
            onClick={() => setEditedElement(createTariffElement())}
          >
            <PlusOutlined /> {t('pricing.actions.add-element')}
          </Button>
        </div>

        <ElementsList
          list={elements}
          updateList={setElements}
          onElementClick={setEditedElement}
          onDelete={handleElementDelete}
          isReadOnly={isReadOnly}
          currencySymbol={currency}
        />

        <Space className={s.box}>
          <Button
            type={'primary'}
            disabled={isArchived}
            onClick={() => form.submit()}
            loading={isTariffLoading}
          >
            {t('common.actions.save')}
          </Button>
        </Space>
      </Padding>

      <Modal
        open={isNotNull(editedElement)}
        onCancel={() => setEditedElement(null)}
        footer={null}
        width={'90%'}
        destroyOnClose
      >
        {editedElement && (
          <ElementForm
            element={editedElement}
            currencySymbol={currency}
            onSubmit={handleElementSubmit}
            onClose={() => setEditedElement(null)}
            isReadOnly={isReadOnly}
          />
        )}
      </Modal>
    </div>
  );
};

export default memo(TariffDetails);
