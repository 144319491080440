import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Input,
  Select,
  Space,
  Table,
  Typography,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useTranslation } from 'react-i18next';
import styles from 'views/PartnerTariffGroup/PartnerTariffGroupDetails.module.css';
import { Tariff } from 'features/partners-tariffs-groups/types';

const searchParams = [
  {
    value: 'none',
    label: 'none',
  },
  {
    value: 'type-2',
    label: 'type-2',
  },
  {
    value: 'ccs',
    label: 'ccs',
  },
  {
    value: 'chademo',
    label: 'chademo',
  },
  {
    value: 'gbt',
    label: 'gbt',
  },
  {
    value: 'shuko',
    label: 'shuko',
  },
  {
    value: 'tesla',
    label: 'tesla',
  },
];

const daysParams = [
  {
    value: 'Mon',
    label: 'Mon',
  },
  {
    value: 'Tue',
    label: 'Tue',
  },
  {
    value: 'Wed',
    label: 'Wed',
  },
  {
    value: 'Thu',
    label: 'Thu',
  },
  {
    value: 'Fri',
    label: 'Fri',
  },
  {
    value: 'Sat',
    label: 'Sat',
  },
  {
    value: 'Sun',
    label: 'Sun',
  },
];

const currencies = [
  {
    value: 'EUR',
    label: 'EUR',
  },
  {
    value: 'RUB',
    label: 'RUB',
  },
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'KZT',
    label: 'KZT',
  },
  {
    value: 'RSD',
    label: 'RSD',
  },
];

const GroupForm = ({
  name,
  items,
  onChange,
  onChangeItem,
  onChangeItemPrice,
  onChangeItemRestriction,
  addRow,
  addRowRestriction,
  addRowElement,
  removeRow,
  removeRowElement,
  removeRowRestriction,
}: any) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('pricing.columns.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('pricing.columns.price'),
      dataIndex: 'price',
      key: 'price',
      render: (price: any, item: any) => (
        <Input
          value={price}
          type="number"
          onChange={(e) => {
            onChangeItemPrice(
              item.tariffIndex,
              item.elementIndex,
              `${item.name}.price`,
              +e.target.value,
            );
          }}
        />
      ),
    },
    {
      title: t('pricing.columns.vat-percent'),
      dataIndex: 'vatPercent',
      key: 'vatPercent',
      render: (vatPercent: any, item: any) => (
        <Input
          value={vatPercent}
          type="number"
          onChange={(e) => {
            onChangeItemPrice(
              item.tariffIndex,
              item.elementIndex,
              `${item.name}.vatPercent`,
              +e.target.value,
            );
          }}
        />
      ),
    },
    {
      title: t('pricing.columns.step-size'),
      dataIndex: 'stepSize',
      key: 'stepSize',
      render: (stepSize: any, item: any) => (
        <Input
          value={stepSize}
          type="number"
          onChange={(e) => {
            onChangeItemPrice(
              item.tariffIndex,
              item.elementIndex,
              `${item.name}.stepSize`,
              +e.target.value,
            );
          }}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        border: '1px solid #eee',
        borderRadius: '16px',
        padding: '16px',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div></div>
          <FormItem
            style={{ marginBottom: 0 }}
            label={`${t('pricing.labels.group-name')}:`}
          >
            <Input size={'small'} value={name} onChange={onChange} />
          </FormItem>
        </div>
      </div>
      <Divider />
      {items.map((tariff: Tariff, index: number) => (
        <Card key={tariff?.id}>
          {index !== 0 ? (
            <div
              style={{
                position: 'absolute',
                right: 16,
                top: 16,
                display: 'flex',
                justifyContent: 'center',
                fontSize: '20px',
                color: '#c13232',
              }}
            >
              <DeleteOutlined
                onClick={() =>
                  removeRow(
                    `tariffs`,
                    items.filter(
                      (item: any, indexItem: number) => indexItem !== index,
                    ),
                  )
                }
              />
            </div>
          ) : null}
          <Space direction="vertical">
            <Space>
              <Typography.Text type="secondary">
                {t('pricing.labels.currency')}:
              </Typography.Text>{' '}
              <Select
                size="small"
                value={tariff?.currency}
                defaultValue={tariff?.currency}
                style={{ width: 120 }}
                onChange={(value) => {
                  onChangeItem(index, 'currency', value);
                }}
                options={currencies}
              />
            </Space>
            <Space>
              <Typography.Text type="secondary">
                {t('pricing.labels.type')}:
              </Typography.Text>{' '}
              <Input
                size="small"
                value={tariff?.type}
                onChange={(e) => {
                  onChangeItem(index, 'type', e.target.value);
                }}
              />
            </Space>
            <Space>
              <Typography.Text type="secondary">
                {t('pricing.labels.url')}:
              </Typography.Text>{' '}
              <Input
                size="small"
                value={tariff?.url}
                onChange={(e) => {
                  onChangeItem(index, 'url', e.target.value);
                }}
              />
            </Space>
            <Space>
              <Typography.Text type="secondary">
                {t('pricing.labels.min-price')}:
              </Typography.Text>{' '}
              <Input
                size="small"
                value={tariff?.minPrice?.amount}
                type="number"
                onChange={(e) => {
                  onChangeItem(index, 'minPrice.amount', +e.target.value);
                  onChangeItem(
                    index,
                    'minPrice.vatAmount',
                    +e.target.value / 10,
                  );
                }}
              />
            </Space>
            <Space>
              <Typography.Text type="secondary">
                {t('pricing.labels.max-price')}:
              </Typography.Text>{' '}
              <Input
                size="small"
                value={tariff?.maxPrice?.amount}
                type="number"
                onChange={(e) => {
                  onChangeItem(index, 'maxPrice.amount', +e.target.value);
                  onChangeItem(
                    index,
                    'maxPrice.vatAmount',
                    +e.target.value / 10,
                  );
                }}
              />
            </Space>
          </Space>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              marginTop: '16px',
            }}
          >
            {tariff.elements?.map((element, idx) => (
              <React.Fragment key={element.id}>
                <Card>
                  {idx !== 0 ? (
                    <div
                      style={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '20px',
                        color: '#c13232',
                      }}
                    >
                      <DeleteOutlined
                        onClick={() =>
                          removeRowElement(
                            `tariffs.[${index}].elements`,
                            tariff.elements.filter(
                              (item, indexElement) => indexElement !== idx,
                            ),
                          )
                        }
                      />
                    </div>
                  ) : null}
                  <Table
                    size={'small'}
                    pagination={false}
                    className={styles.tariff_table}
                    dataSource={
                      element.tariffPrices
                        ? Object.entries(element.tariffPrices)?.map(
                            ([k, v]) => ({
                              key: k,
                              name: k,
                              elementIndex: idx,
                              tariffIndex: index,
                              price: v.price,
                              vatPercent: v.vatPercent,
                              stepSize: v.stepSize,
                            }),
                          )
                        : []
                    }
                    columns={columns}
                  />
                  <Space direction="vertical">
                    {element.restrictions?.map((restriction, i) => (
                      <Card key={i}>
                        {i !== 0 ? (
                          <div
                            style={{
                              position: 'absolute',
                              right: 16,
                              top: 16,
                              display: 'flex',
                              justifyContent: 'center',
                              fontSize: '20px',
                              color: '#c13232',
                            }}
                          >
                            <DeleteOutlined
                              onClick={() =>
                                removeRowRestriction(
                                  `tariffs.[${index}].elements.[${idx}].restrictions`,
                                  element?.restrictions?.filter(
                                    (item, ind) => ind !== i,
                                  ),
                                )
                              }
                            />
                          </div>
                        ) : null}
                        <Space direction="vertical">
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.connector-types')}:
                            </Typography.Text>{' '}
                            <Select
                              mode="multiple"
                              size="small"
                              style={{ minWidth: 175 }}
                              value={restriction.connectorTypes}
                              defaultValue={restriction.connectorTypes}
                              onChange={(value) =>
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'connectorTypes',
                                  value,
                                )
                              }
                              options={searchParams}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.days')}:
                            </Typography.Text>{' '}
                            <Select
                              mode="multiple"
                              size="small"
                              style={{ minWidth: 175 }}
                              value={restriction.daysOfWeek}
                              defaultValue={restriction.daysOfWeek}
                              onChange={(value) =>
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'daysOfWeek',
                                  value,
                                )
                              }
                              options={daysParams}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.min-kwh')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.minKwh}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'minKwh',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.max-kwh')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.maxKwh}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'maxKwh',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.min-current')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.minCurrent}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'minCurrent',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.max-current')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.maxCurrent}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'maxCurrent',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.min-power')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.minPower}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'minPower',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.max-power')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.maxPower}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'maxPower',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.min-duration')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.minDuration}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'minDuration',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.max-duration')}:
                            </Typography.Text>{' '}
                            <Input
                              size="small"
                              value={restriction?.maxDuration}
                              onChange={(e) => {
                                onChangeItemRestriction(
                                  index,
                                  idx,
                                  i,
                                  'maxDuration',
                                  +e.target.value,
                                );
                              }}
                            />
                          </Space>
                          <Space>
                            <Typography.Text
                              style={{ width: '100px', display: 'block' }}
                              type="secondary"
                            >
                              {t('pricing.labels.time')}:
                            </Typography.Text>{' '}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                              }}
                            >
                              <Input
                                size="small"
                                value={restriction?.time?.from}
                                onChange={(e) => {
                                  onChangeItem(
                                    idx,
                                    index,
                                    'timeRange.from',
                                    e.target.value,
                                  );
                                }}
                              />
                              {' - '}
                              <Input
                                size="small"
                                value={restriction?.time?.to}
                                onChange={(e) => {
                                  onChangeItem(
                                    idx,
                                    index,
                                    'timeRange.to',
                                    e.target.value,
                                  );
                                }}
                              />
                            </div>
                          </Space>
                        </Space>
                      </Card>
                    ))}
                  </Space>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'flex-end',
                      marginTop: '16px',
                    }}
                  >
                    <Button
                      onClick={() =>
                        addRowRestriction(
                          `tariffs.[${index}].elements.[${idx}].restrictions`,
                          element.restrictions,
                        )
                      }
                    >
                      {t('pricing.actions.add-element')}
                    </Button>
                  </div>
                </Card>
              </React.Fragment>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Button
              onClick={() =>
                addRowElement(`tariffs.[${index}].elements`, tariff.elements)
              }
            >
              {t('pricing.actions.add-element')}
            </Button>
          </div>
        </Card>
      ))}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          marginTop: '16px',
        }}
      >
        <Button onClick={() => addRow(`tariffs`, items)}>
          {t('pricing.actions.add-tariff')}
        </Button>
      </div>
    </div>
  );
};

export default GroupForm;
