import { Collapse, Typography } from 'antd';
import React, { FC, memo } from 'react';
import { TariffElementRestriction } from 'features/partners-tariffs-groups/types';
import { connectorIcons } from 'components/view/ConnectorLabel/connector-icons.const';
import { ConnectorLabel } from 'components/view/ConnectorLabel';

type Props = {
  restriction: TariffElementRestriction;
};

const RestrictionView: FC<Props> = ({ restriction }) => (
  <Collapse>
    <Collapse.Panel
      header={restriction.connectorTypes?.map((type, idx) => (
        <ConnectorLabel type={type} key={idx} />
      ))}
      key="1"
    >
      <div>
        <Typography.Text type="secondary">Days:</Typography.Text>{' '}
        {restriction.daysOfWeek?.join(', ')}
      </div>
      <div>
        <Typography.Text type="secondary">Min kwh:</Typography.Text>{' '}
        {restriction.minKwh ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Max kwh:</Typography.Text>{' '}
        {restriction.maxKwh ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Min current:</Typography.Text>{' '}
        {restriction.minCurrent ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Max current:</Typography.Text>{' '}
        {restriction.maxCurrent ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Min power:</Typography.Text>{' '}
        {restriction.minPower ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Max power:</Typography.Text>{' '}
        {restriction.maxPower ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Min duration:</Typography.Text>{' '}
        {restriction.minDuration ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Max duration:</Typography.Text>{' '}
        {restriction.maxDuration ?? '-'}
      </div>
      <div>
        <Typography.Text type="secondary">Time:</Typography.Text>{' '}
        {restriction.time?.from && restriction.time?.to
          ? `${restriction.time.from} - ${restriction.time.to}`
          : '-'}
      </div>
    </Collapse.Panel>
  </Collapse>
);

export default memo(RestrictionView);
