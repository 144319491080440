import { ColumnsType } from 'antd/es/table';
import {
  TariffElementRestriction,
  TariffElement,
} from 'features/partners-tariffs-groups/types';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { PricesView } from './PricesView';
import { RestrictionView } from './RestrictionView';
import { ElementsListColumns } from './types';

export const useElementsListColumns = ({
  currencySymbol,
  onDelete,
  isReadOnly,
}: ElementsListColumns): ColumnsType<TariffElement & { key: string }> => {
  const { t } = useTranslation();

  return [
    {
      dataIndex: 'tariffPrices',
      key: 'tariffPrices',
      render: (prices) => (
        <PricesView prices={prices} currencySymbol={currencySymbol} />
      ),
    },
    {
      dataIndex: 'restrictions',
      key: 'restrictions',
      render: (list: TariffElementRestriction[]) =>
        list
          ? list.map((restriction, idx) => (
              <RestrictionView key={idx} restriction={restriction} />
            ))
          : '-',
    },
    {
      render: (_, item: TariffElement) =>
        !isReadOnly && (
          <Button
            onClick={(evt) => {
              evt.stopPropagation();
              onDelete(item);
            }}
            type={'link'}
          >
            {t('common.actions.delete')}
          </Button>
        ),
      align: 'right',
    },
  ];
};
