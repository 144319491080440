import { Dayjs } from 'dayjs';
import { ConnectorType } from 'features/charge-points/types';

export type TariffParams = {
  partnerId: string;
  groupId: string;
  tariffId: string;
};

export type CopyTariffParams = {
  partnerId: string;
  tariffId: string;
};

export type CreateTariffParams = {
  partnerId: string;
  groupId: string;
};

export type TariffElementRestriction = {
  connectorTypes?: ConnectorType[];
  cpHashTags?: string[];
  daysOfWeek?: string[];
  endDate?: string;
  maxCurrent?: number;
  maxDuration?: number;
  maxKwh?: number;
  maxPower?: number;
  minCurrent?: number;
  minDuration?: number;
  minKwh?: number;
  minPower?: number;
  reservationType?: string;
  startDate?: string;
  tagHashTags?: string[];
  time?: {
    from: string;
    to: string;
  };
};

export type TariffElementPrice = {
  price?: number;
  stepSize?: number;
  vatPercent?: number;
};

export enum TariffElementPriceType {
  Energy = 'energy',
  Time = 'time',
  Flat = 'flat',
}

export type TariffElement = {
  id: string;
  restrictions?: TariffElementRestriction[];
  tariffPrices: {
    [TariffElementPriceType.Energy]?: TariffElementPrice;
    [TariffElementPriceType.Flat]?: TariffElementPrice;
    [TariffElementPriceType.Time]?: TariffElementPrice;
  };
};

export type Tariff = {
  id: string;
  currency: string;
  type: string;
  activatedAt?: string;
  archivedAt?: string;
  description?: string;
  elements: TariffElement[];
  externalSource?: string;
  maxPrice?: {
    amount: number;
    vatAmount: number;
  };
  minPrice?: {
    amount: number;
    vatAmount: number;
  };
  name?: string;
  partnerId?: string;
  purposes?: string[];
  tariffGroupId?: string;
  url?: string;
  validFrom: string;
  validTo?: string;
};

export type TariffFormValues = {
  name?: string;
  currency: string;
  description?: string;
  maxPrice?: {
    amount: number;
    vatAmount: number;
  };
  minPrice?: {
    amount: number;
    vatAmount: number;
  };
  type: string;
  url?: string;
  validFrom: Dayjs;
  validTo?: Dayjs;
  purposes?: string[];
};

export type TariffFormUpdateData = Omit<
  TariffFormValues,
  'validFrom' | 'validTo'
> & {
  validFrom: string;
  validTo: string;
};

export type TariffUpdateDataWithElements = TariffFormUpdateData & {
  elements: TariffElement[];
};

export type ActiveTariffFields = [
  'name',
  'description',
  'url',
  'validFrom',
  'validTo',
];

export type TariffUpdateActiveData = Pick<
  TariffFormUpdateData,
  'name' | 'description' | 'url' | 'validFrom' | 'validTo'
>;

export type TariffEditable = Omit<
  Tariff,
  | 'id'
  | 'activatedAt'
  | 'archivedAt'
  | 'elements'
  | 'partnerId'
  | 'tariffGroupId'
>;
