import {
  TariffElement,
  TariffElementPriceType,
} from 'features/partners-tariffs-groups/types';

export const temporaryElementPrefix = 'TMP##';

export const createTariffElement = (): TariffElement => {
  return {
    id: `${temporaryElementPrefix}${Math.random()}`,
    restrictions: [{}],
    tariffPrices: {
      [TariffElementPriceType.Energy]: { stepSize: 1 },
      [TariffElementPriceType.Time]: { stepSize: 1 },
    },
  };
};

export const updateElementsList = (
  list: TariffElement[],
  element: TariffElement,
) => {
  const existingIdx = list.findIndex(({ id }) => id === element.id);
  if (existingIdx >= 0) {
    const nextElements = [...list];
    nextElements[existingIdx] = element;
    return nextElements;
  } else {
    return [...list, element];
  }
};
